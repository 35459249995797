import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PublicationsComponent from '../components/PublicationsComponent'
import { orderBy } from 'lodash'

export const PublicationsIndexPageTemplate = ({
    title,
    introduction,
    scientificPublications,
}) => {
    return (
        <div className={cx('max-w-1264 mx-auto mb-120 px-24 lg:px-32')}>
            <section className={cx('mt-60 lg:mt-120')}>
                <h1 className={cx('hd-jb text-purple-700')}>{title}</h1>
                {introduction && (
                    <p className={cx('lg:w-1/2 text-21 leading-snug mt-24')}>
                        {introduction}
                    </p>
                )}
            </section>
            {scientificPublications && (
                <PublicationsComponent
                    scientificPublications={scientificPublications}
                    className="mt-48"
                />
            )}
        </div>
    )
}

PublicationsIndexPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    introduction: PropTypes.string,
    scientificPublications: PropTypes.array,
}

const PublicationsIndexPage = ({ data }) => {
    const { frontmatter } = data.page
    // NOTE: Order based on filepath (new > old)
    const scientificPublications = orderBy(
        data.scientificPublications.edges,
        ['node.fileAbsolutePath'],
        ['desc']
    )

    return (
        <Layout
            title={frontmatter.title}
            description={frontmatter.introduction}
        >
            <PublicationsIndexPageTemplate
                title={frontmatter.title}
                introduction={frontmatter.introduction}
                scientificPublications={scientificPublications}
                featuredLogoGrid={frontmatter.featuredLogoGrid}
            />
        </Layout>
    )
}

PublicationsIndexPage.propTypes = {
    data: PropTypes.shape({
        page: PropTypes.object,
        scientificPublications: PropTypes.array,
    }),
}

export default PublicationsIndexPage

export const pageQuery = graphql`
    query PublicationsIndexPageTemplate {
        page: markdownRemark(
            frontmatter: { templateKey: { eq: "publications-index-page" } }
        ) {
            frontmatter {
                title
                introduction
            }
        }
        scientificPublications: allMarkdownRemark(
            sort: { order: DESC, fields: frontmatter___date }
            filter: {
                fileAbsolutePath: {
                    regex: "/(collections/scientific-publications)/"
                }
            }
        ) {
            edges {
                node {
                    fileAbsolutePath
                    frontmatter {
                        author
                        description
                        publication
                        title
                        url
                        year
                    }
                }
            }
        }
    }
`
