import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import ArrowRight from '../img/inline/arrow-right.svg'
import ResourceBlockHeading from './ResourceBlockHeading'

const PublicationsComponent = ({
    publicationsHeading,
    scientificPublications,
    className,
}) => {
    return (
        <section id="scientific-publications" className={cx(className)}>
            {publicationsHeading && (
                <ResourceBlockHeading
                    heading={publicationsHeading}
                    viewAllText="Publications"
                    viewAllSlug="/scientific-publications"
                />
            )}

            {scientificPublications && (
                <ul
                    className={cx(
                        'text-white md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-32 md-d:space-y-32'
                    )}
                >
                    {scientificPublications.map((item, i) => {
                        const {
                            title,
                            publication,
                            author,
                            year,
                            description,
                            url,
                        } = item.node.frontmatter
                        const isOdd = i % 2 === 1

                        return (
                            <li
                                key={`${title}-${i}`}
                                className={cx(
                                    'bg-blue-900 group overflow-hidden transform',
                                    'lg:hover:scale-105 lg:focus:scale-105 duration-200 ease-in'
                                )}
                            >
                                <a
                                    href={url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={cx(
                                        'block relative w-full h-400 group'
                                    )}
                                >
                                    <div
                                        className={cx(
                                            'h-full w-full absolute top-0 left-0',
                                            'group-hover:invisible group-focus:invisible'
                                        )}
                                    >
                                        <div
                                            className={cx(
                                                'h-full bg-purple-700 p-24'
                                            )}
                                        >
                                            {title && (
                                                <h3
                                                    className={cx(
                                                        'text-18 leading-snug break-words'
                                                    )}
                                                >
                                                    {title}
                                                </h3>
                                            )}
                                        </div>
                                        {publication || author || year ? (
                                            <div
                                                className={cx(
                                                    'absolute w-full bottom-0',
                                                    {
                                                        'bg-resources-pub-odd': isOdd,
                                                        'bg-resources-pub-even': !isOdd,
                                                    }
                                                )}
                                            >
                                                <p
                                                    className={cx(
                                                        'p-24 pt-64 text-12 leading-snug font-medium break-words'
                                                    )}
                                                >
                                                    {author && (
                                                        <span
                                                            className={cx(
                                                                'block text-16 leading-normal'
                                                            )}
                                                        >
                                                            {author}
                                                        </span>
                                                    )}
                                                    {publication && (
                                                        <span>
                                                            {publication}
                                                        </span>
                                                    )}
                                                    {year && (
                                                        <time dateTime={year}>
                                                            , {year}
                                                        </time>
                                                    )}
                                                </p>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div
                                        className={cx(
                                            'w-full h-full absolute top-0 left-0',
                                            'invisible group-hover:visible group-focus:visible'
                                        )}
                                    >
                                        <div className={cx('bg-blue-900 p-24')}>
                                            {description && (
                                                <p
                                                    className={cx(
                                                        'text-18 leading-snug break-words'
                                                    )}
                                                >
                                                    {description}
                                                </p>
                                            )}
                                        </div>
                                        <p
                                            className={cx(
                                                'flex items-center justify-between absolute w-full bottom-0',
                                                'bg-purple-700 text-16 font-medium leading-snug p-24',
                                                'lg:group-hover:animate-slide-up lg:group-focus:animate-slide-up lg:hover:delay-200'
                                            )}
                                        >
                                            <span>Read Now</span>
                                            <ArrowRight />
                                        </p>
                                    </div>
                                </a>
                            </li>
                        )
                    })}
                </ul>
            )}
        </section>
    )
}

PublicationsComponent.propTypes = {
    publicationsHeading: PropTypes.string,
    scientificPublications: PropTypes.array,
    className: PropTypes.string,
}

export default PublicationsComponent
